import { observable, computed, action, makeObservable } from 'mobx'

import { SUB_PATH } from "@common/config";
import * as authApi from '../apis/auth'
import userStore from './user'
import commonStore from './common'

class AuthStore {

    token = undefined
    constructor() {
        makeObservable(this, {
            token: observable,
            login: action,
            logout: action
        })
    }

    async login(username, password) {
        commonStore.setToken(undefined)
        const res = await authApi.login(username, password)
        commonStore.setToken(res.token)
        await userStore.pullUser()
    }

    logout() {
        commonStore.setToken(undefined)
        userStore.forgetUser()
        window.location = SUB_PATH + '/login'
    }

    async resetPassword(email) {
        return await authApi.resetPassword(email)
    }
}

export default new AuthStore()
