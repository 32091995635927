import { observable, action, reaction, makeObservable } from 'mobx'

const API_TOKEN = 'API_TOKEN'

class CommonStore {

    token = window.localStorage.getItem(API_TOKEN)

    constructor() {
        makeObservable(this, {
            token: observable,
            setToken: action
        })

        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem(API_TOKEN, token)
                } else {
                    window.localStorage.removeItem(API_TOKEN)
                }
            }
        )
    }

    setToken(token) {
        this.token = token
    }
}



export default new CommonStore()