import React from 'react'
import { THEME_CONFIG } from '../common/constants'
import classnames from 'classnames'

const FullPageLayout = ({ children, ...rest }) => {
    return (
        <div
            className={classnames(
                'full-layout wrapper bg-full-screen-image blank-page dark-layout',
                {
                    'layout-dark': THEME_CONFIG.layoutDark
                }
            )}
        >
            <div className='app-content'>
                <div className='content-wrapper'>
                    <div className='content-body'>
                        <div className='flexbox-container'>
                            <main className='main w-100'>{children}</main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FullPageLayout
