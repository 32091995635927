import { getRequest, postRequest, putFormRequest, deleteRequest, putRequest } from "../common/utils"

export function updatePassword(data) {
    return postRequest(`/account/updatePassword`, data)
}


export function create(data) {
    return postRequest(`/account`, data)
}

export function getUserById(id) {
    return getRequest(`/account/${id}`)
}

export function update(data) {
    return putRequest(`/account`, data)
}

export function updateWithImg(data) {
    return putFormRequest(`/account/image`, data)
}

export function deleteById(id) {
    return deleteRequest(`/account/${id}`)
}

export function getByCriteria(criteria) {
    return getRequest(`/account`, criteria)
}

export function getLineToken(id, code) {
    return getRequest(`/account/get-line-notify/${id}/${code}`)
}

export function disconnectLineNotify(data) {
    return putRequest(`/account/disable-line-notify`, data)
}
