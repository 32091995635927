import { postRequest, getRequest, postFormRequest, putFormRequest, deleteRequest } from '../common/utils'

export function getByCriteria(criteria) {
    return getRequest(`/asset`, criteria)
}

export function getSummaryByCriteria(criteria) {
    return getRequest(`/assetSummary`, criteria)
}

export function getByCriteriaAndGroup(criteria) {
    return getRequest(`/asset-criteriaandgroup`, criteria)
}

export function getByIdAndCriteriaAndGroup(criteria, id) {
    return getRequest(`/asset-criteriaandgroup/${id}`, criteria)
}

export function getById(id) {
    return getRequest(`/asset/${id}`)
}

export function getFilterData() {
    return getRequest(`/asset-filterData`)
}

export function getUnassigned() {
    return getRequest(`/asset/get/unassigned`)
}

export function getAssigned() {
    return getRequest(`/asset/get/assigned`)
}

export function updateAssetShop(data) {
    return postRequest(`/asset/update/shop`, data)
}

export function getNoCdu(shopId) {
    return getRequest(`/asset/no-cdu/${shopId}`)
}

export function setCduAssetId(assetId, cduAssetId) {
    return postRequest(`/asset/set-cdu/${assetId}`, { cduAssetId })
}

export function unsetCduAssetId(assetId) {
    return postRequest(`/asset/unset-cdu/${assetId}`)
}

export function getOptionsData() {
    return getRequest(`/asset-optionsData`)
}

export function create(data) {
    return postFormRequest(`/asset`, data)
}

export function update(data) {
    return putFormRequest(`/asset`, data)
}

export function deleteById(id) {
    return deleteRequest(`/asset/${id}`)
}

export function getAssetBar(criteria) {
    return getRequest(`/asset-bar`, criteria)
}

export function getAssetOfCdu(shopId, cduId) {
    return getRequest(`/asset-of-cdu/${shopId}/${cduId}`)
}

export function getRefrigUnlinkByCriteria(criteria) {
    return getRequest(`/asset-refrig-unlink`, criteria)
}
