import _ from 'lodash'
import {SUB_PATH} from '@common/config'
import React from 'react'
import moment from 'moment-timezone'
import {MobXProviderContext, useObserver} from 'mobx-react'

import {
    MONTH_TH,
    WEEKDAY_TH,
    SHOP_STATUS,
    COLOR,
    ResponseStatus,
    HEALTH_SCORE_PARAMETER,
    ALERT_PARAMETER,
    TAB
} from './constants'
import config from './config'
import userStore from '@stores/user'

function stringifyParams(params) {
    const keys = Object.keys(params)
    if (keys.length === 0) {
        return ''
    }

    const q = keys.reduce((acc, key) => {
        if (params[key]) {
            if (_.isArray(params[key]) || _.isSet(params[key])) {
                const params2 = Array.from(params[key])
                const q2 = params2.reduce((acc2, pValue) => {
                    if (pValue) acc2 += `&${key}=${pValue}`
                    return acc2
                }, '')
                if (q2) acc += q2
            } else {
                acc += `&${key}=${params[key]}`
            }
        }
        return acc
    }, '')

    return q.substring(1)
}

export function getRequest(urlPath, params = {}) {
    const queryString = stringifyParams(params)
    let headers = {'authorized': localStorage.getItem('API_TOKEN')}

    return new Promise((resolve, reject) => {
        fetch(`${config.baseApiUrl}${urlPath}?${queryString}`, {
            headers: headers,
            method: 'GET'
        })
            .then(res => {
                if (res.status === 401 && window.location.pathname !== '/login') {
                    window.location = SUB_PATH + '/login'
                } else if (res.status === 403 && window.location.pathname !== '/') {
                    alert(`You don't have permission to perform this action.`)
                    window.location = SUB_PATH + '/'
                } else {
                    resolve(res.json())
                }
            })
    })
}

export function getShopStatus(openDate, closeDate) {
    let status = SHOP_STATUS.INACTIVE
    if (closeDate && moment(closeDate).isSameOrBefore(moment(), 'day')) {
        status = SHOP_STATUS.CLOSED
    } else if (openDate && moment(openDate).isSameOrBefore(moment(), 'day')) {
        status = SHOP_STATUS.OPEN
    }
    return status
}

export function postFormRequest(urlPath, data) {
    let headers = {'authorized': localStorage.getItem('API_TOKEN')}
    return new Promise((resolve, reject) => {
        fetch(`${config.baseApiUrl}${urlPath}`, {
            method: 'POST',
            headers: {
                ...headers
            },
            body: data
        })
            .then(res => {
                resolve(res.json())
            })
            .catch(r => {
                console.log(r.message)
            })
    })
}

export function putFormRequest(urlPath, data) {
    let headers = {'authorized': localStorage.getItem('API_TOKEN')}
    return new Promise((resolve, reject) => {
        fetch(`${config.baseApiUrl}${urlPath}`, {
            method: 'PUT',
            headers: {
                ...headers
            },
            body: data
        })
            .then(res => {
                resolve(res.json())
            })
            .catch(r => {
                console.log(r.message)
            })
    })
}

export function postRequest(urlPath, data) {
    let headers = {'authorized': localStorage.getItem('API_TOKEN')}
    return new Promise((resolve, reject) => {
        fetch(`${config.baseApiUrl}${urlPath}`, {
            method: 'POST',
            headers: {
                ...headers,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                resolve(res.json())
            })
            .catch(r => {
                console.log(r.message)
            })
    })
}

export function putRequest(urlPath, data) {
    let headers = {'authorized': localStorage.getItem('API_TOKEN')}
    return new Promise((resolve, reject) => {
        fetch(`${config.baseApiUrl}${urlPath}`, {
            method: 'PUT',
            headers: {
                ...headers,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                resolve(res.json())
            })
            .catch(r => {
                console.log(r.message)
            })
    })
}

export function deleteRequest(urlPath) {
    let headers = {'authorized': localStorage.getItem('API_TOKEN')}
    return new Promise((resolve, reject) => {
        fetch(`${config.baseApiUrl}${urlPath}`, {
            method: 'DELETE',
            headers: {
                ...headers,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                resolve(res.json())
            })
            .catch(r => {
                console.log(r.message)
            })
    })
}

export function postRequestLineToken(data) {
    return new Promise((resolve, reject) => {
        fetch('https://notify-bot.line.me/oauth/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: data
        })
            .then(res => {
                resolve(res.json())
            })
            .catch(r => {
                console.log(r.message)
            })
    })
}

export function dateTimeFormat(dateTimeStr, language = 'th') {
    if (isUndefined(dateTimeStr)) {
        return '-'
    }
    const dateTime = moment.utc(dateTimeStr).local()
    return dateFormat(dateTime, language) + ' เวลา ' + timeFormat(dateTime)
}

export function dateFormat(dateTime, language = 'th') {
    if (isUndefined(dateTime)) {
        return '-'
    }
    if (language === 'en') {
        return moment(dateTime).format('MMM D, YYYY')
    } else if (language === 'th') {
        const dateTimeObj = moment(dateTime)
        const year = dateTimeObj.year() + 543
        const month = MONTH_TH[dateTimeObj.month()].full
        const weekday = WEEKDAY_TH[dateTimeObj.day()].full

        return `วัน${weekday}ที่ ${dateTimeObj.date()} ${month} พ.ศ.${year}`
    }
    return moment(dateTime).format('MMM D, YYYY')
}

export function timeFormat(dateTime) {
    if (isUndefined(dateTime)) {
        return '-'
    }
    return moment(dateTime).format('HH:mm')
}

export function isUndefined(inp) {
    return _.isUndefined(inp) || _.isNull(inp) || _.isNaN(inp)
}

export function isDefined(inp) {
    return !isUndefined(inp)
}

export function isEmpty(inp) {
    return _.isEmpty(inp)
}

export function formatDateInput(date) {
    return date ? moment(date).format('YYYY[-]MM[-]DD') : undefined
}

export function getFirstDayOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1)
}

export function getCurrentDateNumber(date) {
    return moment(date).date()
}

export function getDateDiff(date1, date2) {
    const start = moment(date1)
    const end = moment(date2)
    const duration = moment.duration(end.diff(start))
    return _.ceil(duration.asDays(), 2)
}

export function getMonthDiff(date1, date2) {
    const start = moment(date1)
    const end = moment(date2)
    const duration = moment.duration(end.diff(start))
    return _.ceil(duration.asMonths(), 2)
}

export function getMonthName(date) {
    return moment(date).format('MMMM')
}

export function getHealthScoreCardColor(score) {
    if (isUndefined(score)) return ''
    else if (score > 80) return 'success'
    else if (score > 40) return 'warning'
    else return 'danger'
}

export function getFailureScoreCardColor(score) {
    if (isUndefined(score)) return COLOR.SILVER
    else if (score < 5) return COLOR.GREEN
    else if (score < 20) return COLOR.YELLOW
    else return COLOR.RED
}

export function resolvedAlert(alert, lastReadDate) {
    const alertsOrEmpty = alert || []
    let res = {}
    alertsOrEmpty.forEach(value => {
        if (!res[value.name]) {
            res[value.name] = {
                name: value.name,
                cnt: 0,
                startDate: moment.utc(value.readDate),
                endDate: moment.utc(value.readDate)
            }
        }
        res[value.name].cnt = res[value.name].cnt + 1
        res[value.name].startDate = moment.min(res[value.name].startDate, moment.utc(value.readDate))
        res[value.name].endDate = moment.max(res[value.name].endDate, moment.utc(value.readDate))
    })
    const alerts = Object.values(res).filter(data => data.endDate.isSame(moment.utc(lastReadDate)))
    const alertCount = alerts.length
    return {alerts, alertCount}
}

Array.prototype.lastIndex = function () {
    return this.length - 1
}

String.prototype.isNullOrBlank = function () {
    return isUndefined(this) || _.trim(this).length === 0
}

export function apiResponseHandler(response, successCallback, failedCallback) {
    if (response.status === ResponseStatus.SUCCESS) return successCallback()

    return failedCallback()
}

export function injectStore(storesName, baseComponent) {
    const component = ownProps => {
        const store = React.useContext(MobXProviderContext)
        const finalStore = {}
        storesName.forEach(storeName => {
            finalStore[storeName] = store[storeName]
        })
        return useObserver(() => baseComponent({...finalStore, ...ownProps}))
    }
    component.displayName = baseComponent.name
    return component
}

export function getModulePermission(moduleId) {
    const roleModules = userStore.currentUser.user.role.RoleModule
    let roleModule = {
        isCreate: true,
        isChange: true,
        isDelete: true,
    }
    const filter = roleModules.find(r => r.modulePath == moduleId)
    if (filter) {
        roleModule.isCreate = filter.isCreate
        roleModule.isChange = filter.isChange
        roleModule.isDelete = filter.isDelete
    }
    return roleModule
}

export function getRoleName() {
    return userStore.currentUser.user.role.title
}

export function getUserProfile() {
    return userStore.currentUser.user
}

export function getStartOfDayUTC(date) {
    const inputDate = date ? new Date(date) : new Date()
    return moment.utc(moment(inputDate).format('YYYY-MM-DD') || '').startOf('day').subtract(7, 'hours')
}

export function getEndOfDayUTC(date) {
    const inputDate = date ? new Date(date) : new Date()
    return moment.utc(moment(inputDate).format('YYYY-MM-DD') || '').endOf('day').subtract(7, 'hours')
}

export function convertToDatetimeUTC(timestamp = null) {
    return moment.utc(moment(timestamp)).format('YYYY-MM-DDTHH:mm:ss')
}

export function convertUTCToLocalTime(inputDateTime) {
    return moment.utc(inputDateTime).local().format('YYYY-MM-DD HH:mm')
}

export function toCamel(s) {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '')
    }).replace('_', '')
}

export function buildChartFromParameter(parametersDataByGroup, oldCharts, newGraphData, type = 'HISTORICAL_TAB', isRemoveFirst) {

    let tempData = parametersDataByGroup.Temp
    let statusData = parametersDataByGroup.Status
    let pressureData = parametersDataByGroup.Pressure
    let performanceData = parametersDataByGroup.Performance
    let currentData = parametersDataByGroup.Current
    let superheatData = parametersDataByGroup.Superheat
    let rpsData = parametersDataByGroup.RPS

    let healthScoreSeries = []
    let alertSeries = []

    let tempSeries = []
    let statusSeries = []
    let pressureSeries = []
    let performanceSeries = []
    let currentSeries = []
    let superheatSeries = []
    let rpsSeries = []

    // Build Health Score and Alert (Apply for all equipment)
    if (type === TAB.HISTORICAL) {
        healthScoreSeries = buildSeriesAndColor([HEALTH_SCORE_PARAMETER], newGraphData.healthScore)
        alertSeries = buildSeriesAndColor([ALERT_PARAMETER], newGraphData.alert)
    }

    //TODO: FIX FOR SUPPORT NOW TAB
    if (tempData) {
        tempSeries = oldCharts ?
            updateSeriesAndColor(tempData, oldCharts.tempChart, newGraphData) :
            buildSeriesAndColor(tempData, type === TAB.HISTORICAL ? newGraphData.historicalData : newGraphData)
    }

    if (statusData) {
        statusSeries = oldCharts ?
            updateSeriesAndColor(statusData, oldCharts.statusChart, newGraphData) :
            buildSeriesAndColor(statusData, type === TAB.HISTORICAL ? newGraphData.historicalData : newGraphData)
    }

    if (pressureData) {
        pressureSeries = oldCharts ?
            updateSeriesAndColor(pressureData, oldCharts.pressureChart, newGraphData) :
            buildSeriesAndColor(pressureData, type === TAB.HISTORICAL ? newGraphData.historicalData : newGraphData)
    }

    if (performanceData) {
        performanceSeries = oldCharts ?
            updateSeriesAndColor(performanceData, oldCharts.performanceChart, newGraphData) :
            buildSeriesAndColor(performanceData, type === TAB.HISTORICAL ? newGraphData.historicalData : newGraphData)
    }

    if (currentData) {
        currentSeries = oldCharts ?
            updateSeriesAndColor(currentData, oldCharts.currentChart, newGraphData) :
            buildSeriesAndColor(currentData, type === TAB.HISTORICAL ? newGraphData.historicalData : newGraphData)
    }

    if (superheatData) {
        superheatSeries = oldCharts ?
            updateSeriesAndColor(superheatData, oldCharts.superheatChart, newGraphData) :
            buildSeriesAndColor(superheatData, type === TAB.HISTORICAL ? newGraphData.historicalData : newGraphData)
    }

    if (rpsData) {
        rpsSeries = oldCharts ?
            updateSeriesAndColor(rpsData, oldCharts.rpsChart, newGraphData) :
            buildSeriesAndColor(rpsData, type === TAB.HISTORICAL ? newGraphData.historicalData : newGraphData)
    }

    return {
        healthChartData: healthScoreSeries,
        alertChartData: alertSeries,
        tempChartData: tempSeries,
        statusChartData: statusSeries,
        pressureChartData: pressureSeries,
        performanceChartData: performanceSeries,
        currentChartData: currentSeries,
        superheatChartData: superheatSeries,
        rpsChartData: rpsSeries,
    }
}

function updateSeriesAndColor(parameters, oldChart, newGraphData) {
    let dataSeries = []
    let colors = []
    const newData = newGraphData[0]

    for (let parameter of parameters) {
        let filterData = oldChart.series.filter(item => item.name === parameter.description)

        if (filterData) {
            filterData = filterData[0]
            filterData.data.shift()
            filterData.data.push({
                x: convertUTCToLocalTime(newData.readDate),
                y: newData[toCamel(parameter.parameterName)]
            })

            dataSeries.push(filterData)
            colors.push(parameter.color)
        }
    }

    return {
        series: dataSeries,
        colors: colors
    }
}

function buildSeriesAndColor(parameters, newGraphData) {
    let dataSeries = []
    let colors = []

    for (let parameter of parameters) {
        let tempArray = []
        const seriesType = parameter.parameterName === HEALTH_SCORE_PARAMETER.parameterName ? 'bar' : 'area'

        newGraphData?.map(data => {
            tempArray.push({
                x: convertUTCToLocalTime(data.readDate),
                y: data[toCamel(parameter.parameterName)]
            })
        })

        dataSeries.push({name: parameter.description, type: seriesType, data: tempArray})
        colors.push(parameter.color)
    }

    return {
        series: dataSeries,
        colors: colors
    }
}

export function convertMeter(num) {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + 'K' // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(1) + 'M' // convert to M for number from > 1 million
    } else if (num < 900) {
        return num // if value < 1000, nothing to do
    }
}

function buildExportSeries(parameters, graphData, headerData) {
    let dataSeries = []
    graphData?.map(data => {
        let temp = {
            'Branch no.': headerData.branchNo,
            'Branch name': headerData.branchName,
            Province: headerData.province,
            UKEY: data.ukey,
            'Equipment name': headerData.equipmentName,
            Timestamp: convertUTCToLocalTime(data.readDate)
        }
        for (const item of parameters) {
            temp[item.description] = data[toCamel(item.parameterName)]
        }
        dataSeries.push(temp)
    })

    return dataSeries
}

export function buildExportChartFromParameter(parametersDataByGroup, graphData, headerData) {
    let tempData = parametersDataByGroup.Temp
    let statusData = parametersDataByGroup.Status
    let pressureData = parametersDataByGroup.Pressure
    let performanceData = parametersDataByGroup.Performance
    let currentData = parametersDataByGroup.Current
    let superheatData = parametersDataByGroup.Superheat
    let rpsData = parametersDataByGroup.RPS
    let parameters = []

    if (tempData) {
        parameters = parameters.concat(tempData)
    }

    if (statusData) {
        parameters = parameters.concat(statusData)
    }

    if (pressureData) {
        parameters = parameters.concat(pressureData)
    }

    if (performanceData) {
        parameters = parameters.concat(performanceData)
    }

    if (currentData) {
        parameters = parameters.concat(currentData)
    }

    if (superheatData) {
        parameters = parameters.concat(superheatData)
    }

    if (rpsData) {
        parameters = parameters.concat(rpsData)
    }

    return buildExportSeries(parameters, graphData, headerData)
}

export function numberWithCommas(val) {
    if(!val) return 0
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
