import { postRequest, getRequest } from '../common/utils'

// Healthscore
export function getHistoricalHealthScoreDataByShopId(shopId, timestampFrom, timestampTo, dataPeriod) {
    return getRequest(`/iotdata/healthscore/historical`, { shopId, timestampFrom, timestampTo, dataPeriod })
}

// Alert
export function getHistoricalAlertDataByShopId(shopId, timestampFrom, timestampTo, dataPeriod) {
    return getRequest(`/iotdata/alert/historical`, { shopId, timestampFrom, timestampTo, dataPeriod })
}

export function getAlertReasonDataByShopId(shopId, timestampFrom, timestampTo, dataPeriod) {
    return getRequest(`/iotdata/alert/reason`, { shopId, timestampFrom, timestampTo, dataPeriod })
}

export function getAlertDataByCriteria(criteria) {
    return getRequest(`/iotdata/alert/getbycriteria`, criteria)
}

export function getLatestData(iotId, assetId) {
    return getRequest(`/iotdata/latest/${iotId}/${assetId}`)
}

// XWEB
export function getLatestXWebData(ukey) {
    return getRequest(`/iotdata/xweb/latest`, { ukey })
}

export function getHistoricalXWebData(ukey, timestampFrom, timestampTo) {
    return getRequest(`/iotdata/xweb/historical`, { ukey, timestampFrom, timestampTo })
}

export function getHistoricalXWebDataByDataRange(ukey, timestampFrom, timestampTo, dataPeriod) {
    return getRequest(`/iotdata/xweb/historicalDataRange`, { ukey, timestampFrom, timestampTo, dataPeriod })
}

// XWEB_CDU
export function getLatestXWebCduData(ukey) {
    return getRequest(`/iotdata/xweb/cdu/latest`, { ukey })
}

export function getHistoricalXWebCduData(ukey, timestampFrom, timestampTo=null) {
    return getRequest(`/iotdata/xweb/cdu/historical`, { ukey, timestampFrom, timestampTo })
}

export function getHistoricalXWebCduDataByDataRange(ukey, timestampFrom, timestampTo, dataPeriod) {
    return getRequest(`/iotdata/xweb/cdu/historicalDataRange`, { ukey, timestampFrom, timestampTo, dataPeriod })
}

// XWEB_AIR
export function getLatestXWebAirData(ukey) {
    return getRequest(`/iotdata/xweb/air/latest`, { ukey })
}

export function getHistoricalXWebAirData(ukey, timestampFrom, timestampTo=null) {
    return getRequest(`/iotdata/xweb/air/historical`, { ukey, timestampFrom, timestampTo })
}

export function getHistoricalXWebAirDataByDataRange(ukey, timestampFrom, timestampTo, dataPeriod) {
    return getRequest(`/iotdata/xweb/air/historicalDataRange`, { ukey, timestampFrom, timestampTo, dataPeriod })
}

// Meter
export function getSummaryXwebMeter(shopId, timestampFrom, timestampTo) {
    return getRequest(`/iotdata/xweb/meter`, { shopId, timestampFrom, timestampTo })
}

export function getHistoricalXwebMeterDevice(shopId, timestampFrom, timestampTo, period) {
    return getRequest(`/iotdata/xweb/meter/historical`, { shopId, timestampFrom, timestampTo, period })
}

export function getHistoricalXwebMeterGroup(shopId, timestampFrom, timestampTo, period, group) {
    return getRequest(`/iotdata/xweb/meter/historical/group`, { shopId, timestampFrom, timestampTo, period, group })
}
