import { deleteRequest, getRequest, postFormRequest, postRequest, putFormRequest } from '../common/utils'

export function getByCriteria(criteria) {
    return getRequest(`/assetModel`, criteria)
}

export function getById(id) {
    return getRequest(`/assetModel/${id}`)
}

export function getParameterByGroupId(id) {
    return getRequest(`/assetModel-parameter/${id}`)
}

export function getFilterData(data) {
    return getRequest(`/assetModel-filterData`, data)
}

export function getOptionsData(data) {
    return getRequest(`/assetModel-optionsData`, data)
}

export function create(data) {
    return postFormRequest(`/assetModel`, data)
}

export function update(data) {
    return putFormRequest(`/assetModel`, data)
}

export function deleteById(id) {
    return deleteRequest(`/assetModel/${id}`)
}

export function getAssetModelBar(criteria) {
    return getRequest(`/assetModel-group`, criteria)
}

export function getRuleBasedAlarm(id) {
    return getRequest(`/assetModelRuleBasedAlarm/${id}`)
}

export function save(data) {
    return postRequest(`/assetModelRuleBasedAlarm`, data)
}

export function getRuleBasedAlarmList(id) {
    return getRequest(`/assetModelRuleBasedAlarmList/${id}`)
}

export function getMlGroupMaster(data) {
    return getRequest(`/assetModel-ml-group-master`, data)
}
