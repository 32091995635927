import * as shop from './shop'
import * as shopBrand from './shopBrand'
import * as province from './province'
import * as asset from './asset'
import * as assetModel from './assetModel'
import * as auth from './auth'
import * as iotData from './iotData'
import * as iotDataSummary from './iotDataSummary'
import * as account from './account'
import * as accountShop from './accountShop'

export default {
    shop, shopBrand, province, asset, assetModel, auth, iotData, iotDataSummary, account, accountShop
}