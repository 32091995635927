import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import { Layout } from './utility/context/Layout'
import * as serviceWorker from './serviceWorker'
import Spinner from './components/@vuexy/spinner/Fallback-spinner'
import userStore from './stores/user'
import authStore from './stores/auth'
import commonStore from './stores/common'
import customizerStore from './stores/customizer'

import './index.scss'
import './@fake-db'

const LazyApp = lazy(() => import('./App'))

const store = {
    authStore,
    commonStore,
    userStore,
    customizerStore
}

configure({ enforceActions: 'observed' })

ReactDOM.render(
    <Provider {...store}>
        <Suspense fallback={<Spinner />}>
            <Layout>
                <LazyApp />
            </Layout>
        </Suspense>
    </Provider>,
    document.getElementById("root")
)

serviceWorker.unregister()
