import { getRequest, putFormRequest, postFormRequest } from '../common/utils'

export function getByCriteria(criteria) {
    return getRequest(`/shopBrand`, criteria)
}

export function getById(id) {
    return getRequest(`/shopBrand/${id}`)
}

export function create(data) {
    return postFormRequest(`/shopBrand`, data)
}

export function update(data) {
    return putFormRequest(`/shopBrand`, data)
}
