import { getRequest, postRequest } from '../common/utils'

export function login(username, password) {
    return postRequest(`/auth/login`, { username, password })
}

export function loginFailed() {
    return postRequest(`/auth/loginFailed`)
}

export function current() {
    return getRequest(`/currentUser`)
}

export function checkPassword(username, password) {
    return postRequest(`/auth/checkPassword`, { username, password })
}

export function resetPassword(email) {
    return postRequest(`/auth/reset-password`, { email })
}
