import { action, observable, extendObservable, makeObservable } from 'mobx'
import { THEME_CONFIG } from '../common/constants'

class CustomizerStore {

    theme = undefined
    sidebarCollapsed = undefined
    navbarColor = undefined
    navbarType = undefined
    footerType = undefined
    menuTheme = undefined
    hideScrollToTop = undefined

    constructor() {
        makeObservable(this, {
            changeMode: action,
            collapseSidebar: action,
            changeNavbarColor: action,
            changeNavbarType: action,
            changeFooterType: action,
            changeMenuColor: action,
            changeHideScrollToTop: action
        })
        extendObservable(this, THEME_CONFIG)

    }

    changeMode = (mode) => {
        this.theme = mode
    }
    collapseSidebar = (value) => {
        this.sidebarCollapsed = value
    }
    changeNavbarColor = (color) => {
        this.navbarColor = color
    }
    changeNavbarType = (style) => {
        this.navbarType = style
    }
    changeFooterType = (style) => {
        this.footerType = style
    }
    changeMenuColor = (style) => {
        this.menuTheme = style
    }
    changeHideScrollToTop = (value) => {
        this.hideScrollToTop = value
    }

}

export default new CustomizerStore()