import { observable, action, makeObservable } from 'mobx'

import * as authApi from '../apis/auth'

class UserStore {

    currentUser = undefined

    constructor() {
        makeObservable(this, {
            currentUser: observable,
            pullUser: action,
            forgetUser: action,
            getCurrentUser: action,
            setCurrentUser: action
        })
    }

    async pullUser() {
        this.forgetUser()
        const res = await authApi.current()
        if (res.user) {
            this.setCurrentUser(res)
            return true
        } else {
            return false
        }
    }

    forgetUser() {
        this.currentUser = undefined
    }

    getCurrentUser() {
        return this.currentUser
    }

    isAdmin() {
        try {
            return this.getCurrentUser().user.username === 'admin'
        } catch (e) {
            return false
        }
    }

    setCurrentUser(user) {
        this.currentUser = user
    }

}

export default new UserStore()