import React from 'react'
import {UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle} from 'reactstrap'
import {SUB_PATH} from "@common/config";
import axios from 'axios'
import * as Icon from 'react-feather'
import authStore from "@stores/auth";
import apis from "../../../apis";

const UserDropdown = props => {
    return (
        <DropdownMenu right>
            <DropdownItem tag='a' href={SUB_PATH + `/my-account`}>
                <Icon.User size={14} className='mr-50'/>
                <span className='align-middle'>Edit Profile</span>
            </DropdownItem>
            <DropdownItem
                tag='a'
                href='#'
                onClick={e => authStore.logout()}
            >
                <Icon.Power size={14} className='mr-50'/>
                <span className='align-middle'>Log Out</span>
            </DropdownItem>
        </DropdownMenu>
    )
}

class NavbarUser extends React.PureComponent {
    state = {
        navbarSearch: false,
        suggestions: [],
        userProfile: {}
    }

    initData = async () => {
        const id = this.props.userId
        if (id) {
            const response = await apis.account.getUserById(id)
            if (response.data) {
                this.setState({
                    userProfile: response.data
                })
            }
        }
    }

    componentDidMount() {
        this.initData()
        axios.get('/api/main-search/data').then(({data}) => {
            this.setState({
                suggestions: data.searchResult
            })
        })
    }

    handleNavbarSearch = () => {
        this.setState({
            navbarSearch: !this.state.navbarSearch
        })
    }

    render() {
        return (
            <ul className='nav navbar-nav navbar-nav-user float-right'>
                <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
                    <DropdownToggle tag='a' className='nav-link dropdown-user-link'>
                        <div className='user-nav d-sm-flex d-none'>
                            <span className='user-name text-bold-600'>{this.state.userProfile.name}</span>
                            <span className='user-status'>{this.state.userProfile.company}</span>
                        </div>
                        <span data-tour='user'>
                            <img
                                src={this.state.userProfile.userImageUrl}
                                className='round'
                                height='40'
                                width='40'
                                alt='avatar'
                            />
                        </span>
                    </DropdownToggle>
                    <UserDropdown {...this.props} />
                </UncontrolledDropdown>
            </ul>
        )
    }
}

export default NavbarUser
